import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom"
import TopBar from "../../components/TopBar";
import BottomBar from "../../components/BottomBar";
import { postDownload } from "../../util/api/downloadApi";
import { useDispatch } from "react-redux";
import { setError } from "../../actions/notificationActions";
import { useEffect } from "react";

const DownloadProviderPage = () => {
  const { os, productID } = useParams();


  const handleStart = () => {
    const url = `/downloadmanager/${productID}/${os}`
    const handle = window.open(url, "_blank");
    handle?.blur()
    window.focus();
    // postDownload(productID, os).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', response.headers?.["x-file-name"]); //or any other extension
    //   document.body.appendChild(link);
    //   link.click();
    // }).catch((e) => {
    //   dispatch(setError(e?.response?.data?.detail))
    // })
  }

  return <div
    style={{ minHeight: "100vh", display: "flex", flexDirection: "column", background: "radial-gradient(circle, rgb(204, 204, 214) 0%, rgb(86, 86, 105) 100%)" }}
  >
    <TopBar />
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        color: "black",
        minHeight: "100vh"
      }}
    >
      <Typography variant="h1">Your download will start soon, enjoy!</Typography>
      <Button onClick={handleStart} sx={{ color: "black" }}>Click here if it does not start automatically</Button>
      <iframe style={{ display: "none" }} src={`/downloadmanager/${productID}/${os}`} />
    </Box>

    <BottomBar />

  </div>
}

export default DownloadProviderPage
