import "./style.scss";

import React, { useEffect, useState } from "react";
import { Typography, Box, Button, CircularProgress, Skeleton } from "@mui/material";

import TopBar from "../../../components/TopBar";

import BottomBar from "../../../components/BottomBar";
import EmailHarvesting from "../../../components/EmailHarvesting";

import ProductDownload from "../../../components/ProductDownload";
import { getProduct } from "../../../util/api/productApi";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import { AmplifierBanner, CabinetBanner, StandAloneBanner, MainBanner } from "../../../components/Products/CNAmp";

import AccountDialog from "../../../components/AccountDialog";
import BuyButton from "../../../components/BuyButton";

import { Helmet } from 'react-helmet';
import OverviewBanner from "../../../components/Products/CNAmp/Overview";
import DynamicsBanner from "../../../components/Products/CNAmp/DynamicsBanner";
import YoutubeBanner from "../../../components/Products/CNAmp/YoutubeBanner";

const PRODUCT_ID = "ecocide-amplifier-suite"

const ExtraContentRightTopBar = ({ trigger, onTrial, priceContent, product }: any) => {
  return <Box
    className={trigger ? "reveal active" : "reveal"}
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "row",
      columnGap: 2,
      color: "#fff",
      alignItems: "center"
    }}>
    {priceContent}
    <Button
      variant="outlined"
      size="small"
      onClick={onTrial}
    >
      Free Trial
    </Button>
    <BuyButton size="small" product={product} sx={{ width: "100px" }} />
  </Box>
}

const ExtraContentMobile = ({ product, priceContent }: any) => {
  return <Box sx={{
    p: 2,
    display: "flex",
    flexDirection: "row",
    columnGap: 2,
    color: "#fff",
    alignItems: "center"
  }}>
    {priceContent}
    <BuyButton size="small" product={product} sx={{ width: "100px" }} />
  </Box>
}

const EcocideAmplifierSuitePage: React.FC<any> = ({ useHelmet = true }: any) => {

  const [downloadOpen, setDownloadOpen] = useState(false);
  const [product, setProduct] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState<any>(undefined)
  const [trigger, setTrigger] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  window.onscroll = function () {
    var scrollLimit = 500;
    setTrigger(window.scrollY >= scrollLimit);
  };

  const dispatch = useDispatch();

  const fetchProduct = () => {
    getProduct(PRODUCT_ID).then((res) => {
      setLoading(false)
      setProduct(res?.data)

    }).catch(() => {
      setLoading(false)
      dispatch(setError("Something went wrong, please contact support."))
    })
  }

  useEffect(() => {
    fetchProduct()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (product) {
      setPrice(`€${(product?.default_price?.unit_amount / 100).toFixed(2)}`)
    }
  }, [product])

  const handleTrial = () => {
    setDownloadOpen(true)
  }

  const PriceContent = () => {
    return <Box sx={{
      p: { xs: 0, md: 2 },
      display: "flex",
      flexDirection: "column",
      columnGap: 2,
      color: "#fff",
      alignItems: "center",
      justifyContent: "left",
      textAlign: "left"
    }}>
      <Typography variant="caption">
        ToneSuite - Ecocide
      </Typography>
      <Typography sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}>
        {price}
        {price === undefined && <Skeleton variant="text" width={100} />}
      </Typography>
    </Box>
  }

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {useHelmet && <Helmet>
        <title>WaveMind Audio | Tone Suite Ecocide</title>
        <link rel="canonical" href="https://wavemind.net/product/ecocide-amplifier-suite" />
      </Helmet>}
      <TopBar menuValue="0"
        hideTrigger={trigger}
        rightExtraAction={<ExtraContentRightTopBar trigger={trigger} product={product} onTrial={handleTrial} priceContent={<PriceContent />} />}
        extraContentMobile={trigger ? <ExtraContentMobile product={product} priceContent={<PriceContent />} /> : undefined} />
      {/* <CouponAlert /> */}
      <ProductDownload requireEmail open={downloadOpen} onClose={() => setDownloadOpen(false)} productID={PRODUCT_ID} />
      <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} />

      <MainBanner price={price} product={product} onTrial={handleTrial} />
      <OverviewBanner />
      <AmplifierBanner />
      <CabinetBanner />
      <DynamicsBanner />
      <YoutubeBanner />
      <StandAloneBanner />
      <EmailHarvesting />
      <BottomBar />
    </div>
  );
};

export default EcocideAmplifierSuitePage;
