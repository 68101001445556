import { Dialog, Slide, Button, Card, CardContent, Box, Typography, IconButton } from "@mui/material"
import * as React from 'react';
import { LoggedIn, LoggedOut } from "./AccessControl";
import { useDispatch } from "react-redux";
import { setError } from "../actions/notificationActions";
import EmailField from "./EmailField";
import DownloadIcon from '@mui/icons-material/Download';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ProductDownload = ({ open, onClose, productID, requireEmail, showSocialMedia = false }) => {

  const [showEmail, setShowEmail] = React.useState(true);

  const dispatch = useDispatch();

  const handleDownload = (os) => {
    if (productID === undefined) {
      dispatch(setError("Product ID unavailable, please contact support."))
      return;
    }
    const link = `/downloads/${os}/${productID}`
    window.open(link, "_blank")
    onClose();
  }

  const handleWindowsDownload = () => {
    handleDownload("windows")
  }

  const handleMacDownload = () => {
    handleDownload("mac")
  }

  const DownloadButtons = () => {
    return (
      <div>
        <Box sx={{ mt: 0, mb: 4, width: "100%", textAlign: "center" }}> <Typography variant="h6"> Select your platform  </Typography> </Box>
        <Button sx={{ width: "100%" }} onClick={handleWindowsDownload} variant="contained">Windows 64-bit</Button>

        <Box sx={{ mt: 1, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="subtitle1">or</Typography> </Box>
        <Button sx={{ width: "100%" }} onClick={handleMacDownload} variant="contained">MacOS</Button>
      </div>
    )
  }

  const SocialMediaPrompt = () => {
    return <div style={{ width: "100%", marginTop: 30 }}>
      {/* <Box sx={{ mt: 3, mb: 4, width: "100%", textAlign: "center" }}> <Typography variant="h6"> Like our work? <br />Follow us on social media to stay up to date with new content and giveaways!  </Typography> </Box> */}

      <Typography>Follow us to stay up to date with future content and giveaways!</Typography>
      <Box sx={{ display: "flex", p: 0, width: "100%", justifyContent: "center", mt: 0, }}>

        <IconButton href="https://www.facebook.com/people/WaveMind/100092637661925/" sx={{ pl: 0, m: 0, color: "white" }}><FacebookIcon sx={{ height: 40, width: 40 }} /></IconButton>
        <IconButton href="https://www.instagram.com/wavemindnl/" sx={{ color: "white" }}><InstagramIcon sx={{ height: 40, width: 40 }} /></IconButton>
        <IconButton href="https://www.youtube.com/channel/UC9lKMgbHdEOcKXzS5UJT-GQ" sx={{ color: "white" }}><YouTubeIcon sx={{ height: 40, width: 40 }} /></IconButton>
      </Box>
    </div>
  }

  const EmailInput = () => {
    return (
      <div>
        <Box sx={{ mt: 0, mb: 4, width: "100%", textAlign: "center" }}> <Typography variant="h6"> Please enter your email to start your download</Typography> </Box>



        <EmailField sx={{ display: { xs: "none", md: "flex" }, width: { xs: "280px", md: "500px" } }} onSubmit={() => setShowEmail(false)} buttonText="Download" labelText="Your Email" />

        <EmailField sx={{ display: { xs: "flex", md: "none" }, width: { xs: "280px", md: "500px" } }} onSubmit={() => setShowEmail(false)} buttonText={<DownloadIcon />} labelText="Your Email" />
        <Typography variant="caption">By downloading you agree to our  <a style={{ textDecoration: "underline" }} href="/privacy">privacy policy</a> and subscribe to our newsletter.</Typography>


        <Box sx={{ mb: 1, mt: 3, width: "100%", textAlign: "center" }}> <Typography variant="h6"> Already have an account?</Typography> </Box>

        <Button href="/login?redirect=store" sx={{ width: "50%" }} variant="contained">
          Login
        </Button>
      </div>
    )
  }

  return <Dialog
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
    sx={{ pb: 0 }}
  >
    {/* <LoggedOut>
      <Card sx={{ minWidth: "300px", p: 3, background: "radial-gradient(circle, rgb(60, 60, 60)0%, rgb(30, 30, 30) 100%)" }} >
        <CardContent>
          <Typography sx={{ mb: 4 }}> A WaveMind account is required to activate your trial</Typography>
          <Box sx={{ mt: 0, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="h6">Account </Typography> </Box>
          <Button sx={{ width: "100%" }} href={`/login?redirect=${window.location.pathname}`} variant="contained">Log In</Button>

          <Box sx={{ mt: 1, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="subtitle1">or</Typography> </Box>
          <Button sx={{ width: "100%" }} href="/register" variant="contained">Create Account</Button>
        </CardContent>
      </Card>
    </LoggedOut> */}
    {/* <LoggedIn> */}

    <Card sx={{ textAlign: "center", minWidth: "300px", p: { xs: 0, md: 3, mb: 0, paddingBottom: showSocialMedia ? "10px !important" : 3 }, background: "radial-gradient(circle, rgb(60, 60, 60)0%, rgb(30, 30, 30) 100%)" }} >
      <CardContent sx={{ paddingBottom: showSocialMedia ? "0 !important" : 3 }}>
        <LoggedIn>
          <DownloadButtons />
        </LoggedIn>
        <LoggedOut>
          {requireEmail && showEmail === true && <EmailInput />}
          {(!requireEmail || !showEmail) && <DownloadButtons />}
          {showSocialMedia && <SocialMediaPrompt />}



        </LoggedOut>
      </CardContent>
    </Card>
    {/* </LoggedIn> */}
  </Dialog >
}

export default ProductDownload;
