import React, { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";

import "./TopBar.scss";
import { Avatar, Box, Button, Grid, ListItemIcon, Toolbar, Typography } from "@mui/material";
import TabList from "@mui/lab/TabList/TabList";
import Tab from "@mui/material/Tab/Tab";
import { TabContext } from "@mui/lab";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton/IconButton";
import Drawer from "@mui/material/Drawer/Drawer";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider/Divider";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";

import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import logo from "../static/images/Logo.png";
import { LoggedIn, LoggedOut } from "./AccessControl";
import { useSelector } from "react-redux";

const LoggedOutAccountMenu = ({ anchorEl, open, handleClose }: any) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          pt: 2,
          pl: 4,
          pr: 4,
          pb: 2,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: '#1a1a1a',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          background: "#1a1a1a"
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box sx={{ mt: 0, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="h6">Account </Typography> </Box>
      <Button sx={{ width: "100%" }} href={`/login?redirect=${window.location.pathname}`} variant="contained">Log In</Button>

      <Box sx={{ mt: 1, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="subtitle1">or</Typography> </Box>
      <Button sx={{ width: "100%" }} href="/register" variant="contained">Create Account</Button>
    </Menu >
  )
}

const LoggedInAccountMenu = ({ anchorEl, open, handleClose }: any) => {

  const state: any = useSelector((state) => state)

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          pt: 2,
          pl: 4,
          pr: 4,
          pb: 2,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: '#1a1a1a',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          background: "#1a1a1a"
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box sx={{ p: 2, pt: 0, display: "flex", justifyContent: "center" }}>
        <Avatar />
      </Box>
      <Box sx={{ p: 2, pt: 0, display: "flex", justifyContent: "center" }}>
        <Typography >{state?.token?.data?.username}</Typography>
      </Box>
      <Button sx={{ width: "100%", mb: 2, }} href={"/account"} variant="contained">My Account</Button>
      <Button sx={{ width: "100%" }} href="/logout" variant="contained">Log Out</Button>
    </Menu >
  )
}

const TopBar = ({ menuValue, rightExtraAction, extraContentMobile, hideTrigger }: any) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar style={{ background: "rgba(26,26,26,0.5)", overflow: "hidden" }}>
        <Typography variant="h5"><a href="/"><img src={logo} alt="WaveMind" height={"20px"} /></a></Typography>
        <Grid container justifyContent="flex-end" alignItems="center">

          <Grid container xs={12} md={10} lg={8} justifyContent="flex-end">
            {<Box className={hideTrigger ? "inverseReveal" : "inverseReveal active"}>
              <TabList >
                <Tab label="Home" href={"/"} value={"0"} />
                <Tab label="Software" value="1" href={"/software"} />
                <Tab label="Contact" href={"/contact"} value={"3"} />
                {/* <Tab label="About" href={"/about"} value={"4"} /> */}
              </TabList>
            </Box>}
            {rightExtraAction !== undefined && <Box>
              {rightExtraAction}
            </Box>}
          </Grid>

          {/* <IconButton color={"primary"} href="/cart">
            <ShoppingCartOutlinedIcon />
          </IconButton> */}

          <LoggedIn>
            <IconButton color={"primary"} onClick={handleClick}>
              <PersonIcon />
            </IconButton>
          </LoggedIn>
          <LoggedOut>
            <IconButton color={"primary"} onClick={handleClick}>
              <PermIdentityIcon />
            </IconButton>
          </LoggedOut>
        </Grid>
        <LoggedOut>
          <LoggedOutAccountMenu anchorEl={anchorEl} open={openMenu} handleClose={handleClose} />
        </LoggedOut>
        <LoggedIn>
          <LoggedInAccountMenu anchorEl={anchorEl} open={openMenu} handleClose={handleClose} />
        </LoggedIn>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    type ItemLinkProps = {
      name: string,
      link: string,
      icon?: any
    }
    const ItemLink: React.FC<ItemLinkProps> = ({ name, link, icon }) => {
      return (
        <a href={link}>
          <ListItem key={name}>
            {icon && <ListItemIcon sx={{ color: "white" }}>{icon}</ListItemIcon>}
            <ListItemText primary={name} />
          </ListItem>
        </a>
      );
    }

    return (
      <Toolbar >
        <IconButton
          {...{
            edge: "start",
            color: "secondary",
            onClick: handleDrawerOpen,
          }}

        >
          <MenuIcon />
        </IconButton>
        {extraContentMobile !== undefined && extraContentMobile}
        <Drawer
          {...{
            anchor: "top",
            open: state.drawerOpen,
            onClose: handleDrawerClose,
            variant: "persistent"

          }}
        >
          <div className={"drawerContainer"} >
            <IconButton color={"primary"} onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
            <Divider className={"divider"} light />
            <List>
              <ItemLink name={"Home"} link={"/"} />
              <Divider className={"divider"} light variant={"middle"} />

              <ItemLink name={"Software"} link={"/software"} />
              <Divider className={"divider"} light variant={"middle"} />

              <ItemLink name={"Contact"} link={"/contact"} />
              <Divider className={"divider"} light variant={"middle"} />

              {/* <ItemLink name={"About"} link={"/about"} /> */}
              <Divider className={"divider"} light variant={"middle"} />

              <LoggedIn>
                <ItemLink name="My Account" link="/account" icon={<PersonIcon />} />
                <Divider className={"divider"} light variant={"middle"} />
                <ItemLink name="Log Out" link="/logout" icon={<LogoutIcon />} />
                <Divider className={"divider"} light variant={"middle"} />
              </LoggedIn>

              <LoggedOut>
                <ItemLink name="Log In" link="/login" icon={<LoginIcon />} />
                <Divider className={"divider"} light variant={"middle"} />
                <ItemLink name="Create Account" link="/register" icon={<PersonIcon />} />
                <Divider className={"divider"} light variant={"middle"} />
              </LoggedOut>

            </List>
          </div>
        </Drawer>
        <Box sx={{ ml: "auto", mr: 0 }}>
          <a href="/"><img src={logo} alt="WaveMind" height={"15px"} /></a>
        </Box>
      </Toolbar>
    );
  };

  return (
    <TabContext value={menuValue}>
      <AppBar position="sticky" className="topbar" style={{ background: "rgba(0,0,0,0.25)", backdropFilter: state.mobileView ? "none" : "blur(6px)", maxHeight: 64 }}>
        {state.mobileView ? displayMobile() : displayDesktop()}
      </AppBar>


    </TabContext>
  );
};

export default TopBar;
